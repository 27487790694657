import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';

function App() {
  return (
    <div>
      <Container fluid>
        <Row><h1>Misgnomer</h1></Row>
      </Container>
    </div>
  );
}

export default App;
